<template>
  <BaseDraggableModal :name="modal_name" :fullscreen="true" :ref="modal_name">
    <v-card :loading="loading" :disabled="loading" height="100%">
      <BaseModal>
        <v-toolbar class="draggable_selector" dark color="primary" slot="header" dense flat>
          <v-btn icon @click.stop="reset">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $store.getters.translate(record.name) }}</v-toolbar-title>
          <v-spacer/>
        </v-toolbar>
        <div slot="content">
          <v-divider/>
          <v-card-text style="height: 98vh">
            <v-row style="height: 100%">
              <v-col lg="4" md="12" sm="12" xs="12">
                <v-card flat height="100%" class="d-flex flex-column">
                  <v-card-text>
                    <base-row
                      v-if="record.candidate"
                      :label="$store.getters.translate('candidate')"
                      :value="record.candidate.name"
                      :link="true"
                      @click="editCandidate(record.candidate_id)"/>
                    <base-row
                      v-if="record.dossier && record.dossier_id"
                      :label="$store.getters.translate('dossier')"
                      :value="record.dossier_id"
                      :link="true"
                      @click="editDossier(record.dossier_id)"/>
                    <base-row
                      v-if="record.candidate"
                      :label="$store.getters.translate('date_of_birth')"
                      :value="record.candidate.person.date_of_birth"/>
                    <base-row
                      v-if="record.candidate"
                      :label="$store.getters.translate('place_of_birth')"
                      :value="record.candidate.person.place_of_birth"/>
                    <base-row
                        v-if="record.stipel_certificate"
                        :label="$store.getters.translate('stipel_certificate_number')"
                        :value="record.stipel_certificate.s_certificate_id"/>
                    <v-text-field
                      :label="$store.getters.translate('certificate_number')"
                      v-model="record.certificate_number"
                      append-outer-icon="mdi-content-save"
                      @click:append-outer="save"
                      :disabled="!canEdit"/>
                    <v-text-field
                        :class="'ma-0 pt-'+$store.getters.lineheight"
                        :label="$store.getters.translate('issued_on')"
                        v-model="record.issueing_date"
                        v-mask="'##-##-####'"
                        append-outer-icon="mdi-content-save"
                        @click:append-outer="save"
                        :disabled="!canEdit"/>
                    <v-text-field
                        :class="'ma-0 pt-'+$store.getters.lineheight"
                        :label="$store.getters.translate('valid_until')"
                        v-model="record.valid_until"
                        v-mask="'##-##-####'"
                        append-outer-icon="mdi-content-save"
                        @click:append-outer="save"
                        :disabled="!canEdit"/>
                    <base-row
                      v-if="record.file"
                      :label="$store.getters.translate('last_downloaded_on')"
                      :value="record.file.last_downloaded_on"/>
                    <base-row
                      v-if="record"
                      :label="$store.getters.translate('status')"
                      :value="$store.getters.translate(record.status)"/>
                    <base-log-field :model_type="$models[page.name]" :model_id="record.id"/>
                  </v-card-text>
                  <v-spacer/>
                  <v-card-footer>
                    <v-btn
                      v-if="record.dossier && record.dossier.scheme && record.dossier.scheme.end_result === 'template'"
                      :disabled="!canEdit"
                      @click="rebuild"
                      block
                      color="warning">
                      {{ $store.getters.translate("rebuild_certificate") }}
                    </v-btn>
                    <v-btn
                      :disabled="!canEdit"
                      v-if="record.status !== 'valid'"
                      @click="approve"
                      block
                      color="success"
                      class="mt-5">
                      {{ $store.getters.translate("approve_certificate") }}
                    </v-btn>
                    <v-btn
                      :disabled="!canEdit"
                      v-if="record.status === 'valid'"
                      @click="revoke"
                      block
                      color="secondary"
                      class="mt-5">
                      {{ $store.getters.translate("revoke_certificate") }}
                    </v-btn>
                    <v-btn
                      :disabled="!canEdit"
                      color="red"
                      :dark="canEdit"
                      class="mt-5"
                      @click="archive"
                      block>
                      {{ $store.getters.translate("delete") }}
                    </v-btn>
                  </v-card-footer>
                </v-card>
              </v-col>
              <v-divider vertical/>
              <v-col>
                <v-layout v-if="!blob" align-center justify-center column fill-height>
                  <v-flex class="text-center" row align-center>
                    <div class="justify-center text-center" v-if="!blob">
                      <v-progress-circular :size="100" color="primary" indeterminate/>
                      <transition name="fade">
                        <h3 class="mt-5">{{ message }}</h3>
                      </transition>
                    </div>
                  </v-flex>
                </v-layout>
                <p v-else align="center" style="height: 100%" class="mt-5 mb-5">
                  <iframe
                      ref="preview"
                      style="
                          overflow: hidden;
                          height: 100%;
                          width: 850px;
                          display: block;
                          margin-bottom: 15px;"
                      height="100%"
                      width="800px"
                      :src="blob"
                      frameborder="0"/>
                </p>
              </v-col>
            </v-row>
          </v-card-text>
        </div>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import BaseModal from "../../components/commonComponents/BaseModal";
import BaseDraggableModal from "../../components/commonComponents/BaseDraggableModal";
import helpFunctions from "../../plugins/helpFunctions";

export default {
  props: ["modal_name"],
  components: {BaseModal, BaseDraggableModal},
  data() {
    return {
      page: {
        title: this.$store.getters.translate("certificates"),
        name: "certificates",
        model: "certificate",
      },
      loading: false,
      record: {},
      blob: null,
      message: this.$store.getters.translate("loading_certificate"),
      id: null,
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    load() {
      this.loading = true;
      this.$http
        .get(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.id)
        .then((response) => {
          this.loading = false;
          this.record = response.data;
          if (this.record.custom_fields == null || Array.isArray(this.record.custom_fields) || typeof this.record.custom_fields != "object") {
            this.record.custom_fields = {};
          }
          if(this.record.file) {
            if(this.record.file.content_type === 'text/html') {
              this.loadHtmlFile();
            }
            else {
              this.loadPdfFile();
            }
          }
        })
        .catch((error) => {
          this.$toasted.error(error);
          this.loading = false;
        });
    },
    loadHtmlFile() {
      this.$http
          .get(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.id + "/html", {responseType: "blob"})
          .then((response) => {
            if (response.status === 202) {
              this.blob = null;
              setTimeout(() => this.load(), 6000);
            } else {
              const blob = new Blob([response.data], { type: "text/html" });
              const link = document.createElement("a");
              link.href = URL.createObjectURL(blob);
              this.blob = link.href;
              this.loading = false;
            }
          })
          .catch((error) => {
            this.blob = null;
            this.$toasted.error(error);
            this.loading = false;
          });
    },
    loadPdfFile() {
      this.$http
          .get(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.id + "/pdf", {responseType: "blob"})
          .then((response) => {
            if (response.status === 202) {
              this.blob = null;
              setTimeout(() => this.load(), 6000);
            } else {
              const blob = new Blob([response.data], { type: "application/pdf" });
              const link = document.createElement("a");
              link.href = URL.createObjectURL(blob);
              this.blob = link.href;
              this.loading = false;
            }
          })
          .catch((error) => {
            this.blob = null;
            this.$toasted.error(error);
            this.loading = false;
          });
    },
    save() {
      if(!this.loading) {
        this.$confirm(this.$store.getters.translate("are_you_sure")).then((res) => {
              if (res) {
                this.loading = true;
                this.$http
                    .patch(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.record.id, this.record)
                    .then(() => {
                      this.loading = false;
                      this.$toasted.success(this.$store.getters.translate("saved_succesfully"));
                      this.$emit("refresh");
                    })
                    .catch((error) => {
                      this.$toasted.error(error);
                      this.loading = false;
                    });
              }
            }
        );
      }
    },
    approve() {
      this.$confirm(this.$store.getters.translate("are_you_sure")).then((res) => {
          if (res) {
            this.loading = true;
            let certificate = this.record;
            certificate.new_status = "valid";
            this.$http
              .patch(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + certificate.id, certificate)
              .then(() => {
                this.loading = false;
                this.$toasted.success(this.$store.getters.translate("valid"));
                this.$emit("refresh");
                this.load();
              })
              .catch((error) => {
                this.$toasted.error(error);
                this.loading = false;
              });
          }
        }
      );
    },
    rebuild() {
      this.$confirm(this.$store.getters.translate("rebuild_certificate_confirmation")).then((res) => {
        if (res) {
          this.loading = true;
          let certificate = this.record;
          certificate.rebuild = true;
          this.$http
            .patch(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + certificate.id, certificate)
            .then(() => {
              this.loading = false;
              this.$toasted.success(this.$store.getters.translate("valid"));
              this.$emit("refresh");
              this.load();
            })
            .catch((error) => {
              this.$toasted.error(error);
              this.loading = false;
            });
        }
      });
    },
    revoke() {
      this.$confirm(this.$store.getters.translate("are_you_sure")).then((res) => {
          if (res) {
            this.loading = true;
            this.record.certificate_block_reason = "revoked";
            this.record.new_status = "revoked";
            this.$http
              .patch(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.record.id, this.record)
              .then(() => {
                this.loading = false;
                this.$toasted.success(this.$store.getters.translate("revoked"));
                this.$emit("refresh");
                this.load();
              })
              .catch((error) => {
                this.$toasted.error(error);
                this.loading = false;
              });
          }
        }
      );
    },
    archive() {
      this.$confirm(this.$store.getters.translate("are_you_sure")).then((res) => {
          if (res) {
            this.loading = true;
            this.$http
              .delete(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.record.id + "?force_delete=true")
              .then(() => {
                this.loading = false;
                this.$toasted.success(this.$store.getters.translate("deleted"));
                this.$emit("refresh");
              })
              .catch((error) => {
                this.$toasted.error(error);
                this.loading = false;
              });
          }
        }
      );
    },
    reset() {
      this.record = {
        custom_fields: {},
      };
      this.$emit("refresh");
      this.closeModal(this.modal_name);
    },
    editDossier(id) {
      this.$router.push("/dossiers/" + id);
    },
    editCandidate(id) {
      this.$router.push("/candidates/" + id);
    },
    canEdit() {
      return this.$can("edit", this.page.model);
    },
  },
};
</script>
